import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { persistStore, persistReducer, createMigrate } from 'redux-persist'

const migrations = {}

export const persistConfig = {
  key: 'holzbau.cloud-data',
  version: 1,
  storage: storage,
  // debug: process.env.REACT_APP_DEBUG === 'true' ? true : false,
  stateReconciler: autoMergeLevel2,
  // migrate: createMigrate(migrations, {
  //   debug: process.env.REACT_APP_DEBUG === 'true' ? true : false,
  // }),
}
