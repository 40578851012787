import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { IIfcModelCreationTableRowData } from "../ifcModelCreations";
import PreviewIcon from '@mui/icons-material/Preview';

interface LoadInIfcViewerMenuProps {
    row: IIfcModelCreationTableRowData;
    t: (key: string) => string;
    handleLoadIfcFileClick: (row: IIfcModelCreationTableRowData, isBaseModelFile: boolean) => void;
}

const LoadInIfcViewerMenu: React.FC<LoadInIfcViewerMenuProps> = ({ row, t, handleLoadIfcFileClick }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (isBaseModelFile: boolean) => {
        handleLoadIfcFileClick(row, isBaseModelFile);
        handleClose();
    };

    return (
        <>
            <Tooltip title={t("actions.loadIfcInViewer")}>
                <IconButton
                    onClick={handleClick}
                    aria-label="loadIfcInViewer"
                    size="small"
                >
                    <PreviewIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick(true)}>
                    {t("loadIfcInViewerMenu.base_model_file")}
                </MenuItem>
                {row.created_model_file && (
                    <MenuItem onClick={() => handleMenuItemClick(false)}>
                        {t("loadIfcInViewerMenu.created_model_file")}
                    </MenuItem>
                )}
            </Menu>
        </>

    );
};

export default LoadInIfcViewerMenu;