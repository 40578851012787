import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import DownloadIcon from '@mui/icons-material/Download';
import { IIfcModelCreationTableRowData } from "../ifcModelCreations";

interface DownloadFilesMenuProps {
    row: IIfcModelCreationTableRowData;
    t: (key: string) => string;
    handleDownloadFile: (fileUUID: string) => void;
}

const DownloadFilesMenu: React.FC<DownloadFilesMenuProps> = ({ row, t, handleDownloadFile }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (fileUUID: string) => {
        handleDownloadFile(fileUUID);
        handleClose();
    };

    return (
        <>
            <Tooltip title={t("actions.downloadFiles")}>
                <IconButton
                    onClick={handleClick}
                    aria-label="downloadFiles"
                    size="small"
                >
                    <DownloadIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick(row.base_model_file.uuid)}>
                    {t("downloadFilesMenu.base_model_file")}
                </MenuItem>
                {row.created_model_file && (
                    <MenuItem onClick={() => row.created_model_file && handleMenuItemClick(row.created_model_file.uuid)}>
                        {t("downloadFilesMenu.created_model_file")}
                    </MenuItem>
                )}
                {row.wapla_file && (
                    <MenuItem onClick={() => row.wapla_file && handleMenuItemClick(row.wapla_file.uuid)}>
                        {t("downloadFilesMenu.wapla_file")}
                    </MenuItem>
                )}
            </Menu>
        </>

    );
};

export default DownloadFilesMenu;